<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col cols="12" md="4">
        <b-card title="Signup" class="mb-2" bg-variant="white">
            <b-form @submit="signup">
              <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="name"
                  type="text"
                  inputmode="text"
                  required
                  placeholder="Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2" label="Email address:" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="email"
                  type="email"
                  inputmode="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Password:" label-for="input-3">
                <b-form-input
                  id="input-3"
                  v-model="password"
                  type="password"
                  inputmode="password"
                  required
                  placeholder="Password"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">Submit</b-button>
              <p class="mt-4">
                Already have an account?
                <router-link to="/login">Log in here.</router-link>
              </p>
            </b-form>
            <transition name="fade">
              <div v-if="errorMsg !== ''" class="error-msg">
                <p>{{ errorMsg }}</p>
              </div>
            </transition>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  import { auth } from '../firebaseConfig'
  import { store } from '../store'

  export default {
    name: 'login',
    data() {
      return {
        name: '',
        email: '',
        password: '',
        errorMsg: ''
      }
    },
    methods: {
      async signup (e) {
        e.preventDefault()
        auth.createUserWithEmailAndPassword(this.email, this.password).then(async cred => {
          store.commit('setCurrentUser', cred.user)
          // Copy the demo team and make new user the manager
          let demoTeam = await store.dispatch('getTeamByTsId', 1)
          let userProfile = {
            uid: store.state.currentUser.uid,
            name: this.name,
            teams: [demoTeam.id],
            manages: [demoTeam.id]
          }
          store.commit('setUserProfile', userProfile)
          store.dispatch('updateUserProfile').then(() => {
            if (!store.state.profileReady) {
              store.watch(
                (state) => state.profileReady,
                (value) => {
                  if (value === true) {
                    this.$router.push('/teamlist')
                  }
                }
              )
            }
            this.$router.push('/teamlist')
          })
        })
      }
    }
  }
</script>

<style scoped>  /* "scoped" attribute limit the CSS to this component only */
</style>